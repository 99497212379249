import * as React from "react";
// @ts-ignore
import lettersPatternImage from '../../../static/images/letters_pattern.svg';
// @ts-ignore
import devImage from '../../../static/images/dev0.png';
// @ts-ignore
import tpImage from '../../../static/images/tp1.png';
// @ts-ignore
import anImage from '../../../static/images/an1.png';

export default class ServicesSection extends React.Component {
    render() {
        return (
            <section id="services" className="services">
                <div className="container">
                    <h2 className="title">Наши услуги</h2>
                    <div className="service-list">
                        <div>
                            <figure>
                                <img src={devImage}/>
                            </figure>
                            <div className="info">
                                <h3>Разработка приложений</h3>
                                <p>Информационное, прикладное, системное программное обеспечение. Веб-разработка, мобильные и десктопные приложения.</p>
                            </div>
                        </div>
                        <div>
                            <figure>
                                <img src={tpImage}/>
                            </figure>
                            <div className="info">
                                <h3>Поддержка и сопровождение</h3>
                                <p>Обеспечение стабильной и бесперебойной работы, оперативное консультирование по функциональности системы, решение возникающих проблем.</p>
                            </div>
                        </div>
                        <div>
                            <figure>
                                <img src={anImage}/>
                            </figure>
                            <div className="info">
                                <h3>Аналитика и консалтинг</h3>
                                <p>Оптимизация бизнес-процессов, повышение эффективности работы организации.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <img className="shape" src={lettersPatternImage} alt="Фон"/>
            </section>
        )
    }
}
